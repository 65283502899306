
import { defineComponent, onMounted, ref } from 'vue';

import { usePlans } from '@/composables/api';
import SearchBox from '@/components/keyword-searching/Index.vue';
import Filter, { FilterEvent, FilterOption, FilterType } from '@/components/filter/Index.vue';
import permissionUnits from '@/components/permission-units/index.vue';
import { useRoute } from 'vue-router';
import { useI18n } from 'vue-i18n';


const FILTER_OPTIONS: FilterOption[] = [
  {
    type: FilterType.CHECKBOX,
    label: 'Show'
  },
  {
    type: FilterType.CHECKBOX,
    label: 'Best'
  }
];

export default defineComponent({
  components: {
    SearchBox,
    Filter,
    permissionUnits
  },
  setup() {
    const route = useRoute();
    const page = ref(1);
    const keyword = ref();
    const isBest = ref();
    const show = ref();
    const sortBy = ref();
    const descSortBy = ref('updated_at');
    const { t } = useI18n();


    const { data, isLoading, isFetching } = usePlans({ page, keyword, isBest, show, sortBy, descSortBy });

    const searchKeyword = (_keyword: string) => {
      keyword.value = _keyword;
    };

    const sortChange = ({ prop, order }) => {
      if (!prop) {
        descSortBy.value = '';
        sortBy.value = '';
        return;
      }

      if (order === 'ascending') {
        sortBy.value = prop;
        descSortBy.value = '';
      } else {
        descSortBy.value = prop;
        sortBy.value = '';
      }
    };

    const handleFilterChange = (event: FilterEvent) => {
      // mutate ref
      show.value = event[0] ? '1' : '';
      isBest.value = event[1] ? '1' : '';
    };

    onMounted(() => {
      const routeQuery = route.query;

      if (routeQuery.page && (routeQuery.isEdited !== '1')) {
        page.value = Number(route.query.page);
      }
    });

    return {
      page,
      data,
      isLoading,
      isFetching,
      FILTER_OPTIONS,
      sortChange,
      searchKeyword,
      t,
      handleFilterChange
    };
  }
});
